.SignupHeroCover {
  @apply flex
    h-44
    flex-none
    items-center
    justify-center
    bg-cover
    p-8;
  background-position: center;
}

.SignupHeroCover___completedScreen {
  background-position: 25%;
}

.SignupHeroCover_logo {
  @apply w-32;
}

@screen lg {
  .SignupHeroCover {
    @apply h-auto
      w-[30%]
      max-w-[32rem]
      items-end
      justify-end
      pb-24; /* We add this bottom-padding to push up the logo so we can still see it on top of the live chat widget. */
  }

  .SignupHeroCover_logo {
    @apply w-8;
  }
}
