.SignupLayout {
  @apply flex min-h-screen flex-col gap-8;
}

.SignupLayout_page {
  @apply flex grow flex-col;
}

.SignupLayout_pageContent {
  @apply grow px-6 space-y-8 flex flex-col w-full;
}

.SignupLayout_pageLogo {
  @apply hidden;
}

@screen lg {
  .SignupLayout {
    @apply flex-row-reverse;
  }

  .SignupLayout_pageContent {
    @apply max-w-5xl mx-auto space-y-24;
  }

  .SignupLayout_pageLogo {
    @apply block m-14 w-32;
  }

}
