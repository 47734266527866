.Commitments {
  @apply flex flex-col gap-4
}

.Commitments_buttonGroup {
  @apply gap-4
    grid
    grid-cols-1
    grid-rows-4
}

.Commitments_heading {
  @apply text-center
}

.Commitments_subHeading {
  @apply text-center
}

.Commitments_subHeading___desktop {
  composes: Commitments_subHeading;
  @apply hidden
}

@screen xl {

  .Commitments_heading {
    @apply text-left
  }
  
  .Commitments_subHeading {
    @apply hidden
  }

  .Commitments_subHeading___desktop {
    composes: Commitments_subHeading;
    @apply block text-left
  }
  
  .Commitments_buttonGroup {
    @apply grid-cols-2 grid-rows-2
  }
}
