.PersonalDetailsForm_submitButton {
  @apply flex
    flex-col
    items-center
    gap-6
    self-stretch;
}

.PersonalDetailsForm_notice {
  @apply text-center leading-6 text-shade-300;
}
