.Banner {
  @apply relative
    my-4
    flex
    max-w-lg
    flex-col
    space-y-4
    rounded-sm
    border-l-4
    border-accent-blue
    bg-shade-100
    p-6;
}

.Banner_description {
  @apply flex flex-row items-center space-x-4;
}

.Banner_icon {
  @apply text-2xl;
}

@screen lg {
  .Banner {
    @apply max-w-none flex-row items-center justify-between space-x-8 space-y-0;
  }

  .Banner_ctaButton {
    @apply min-w-button;
  }
}
