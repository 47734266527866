.PillarsMobile_buttons {
  @apply grid grid-cols-2 grid-rows-3 gap-2 z-20 w-full
}

.PillarMobile_description {
  @apply block w-full text-center mt-8
}

.PillarsMobile_button {
  background: var(--primary-gradients-cool-black, linear-gradient(160deg, #1E1E33 0%, #030311 100%));
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  @apply text-accent-blue font-bold p-4 rounded-md
}

.PillarsMobile_button___active {
  @apply border border-accent-blue
}