.PillarsOverview .PillarsMobile figure svg{
  @apply mx-auto
}



@screen xl {
  .PillarsOverview ._textSubheading {
    @apply mb-24
  }
  .PillarsOverview ._fieldContainer {
    @apply mb-24
  }
}