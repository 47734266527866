.PillarsOverview_body {
  @apply flex-row mx-auto
}

.PillarsOverview_body > figure {
  width: 80%;
  @apply flex justify-center z-10 mx-auto;
}

.PillarsDesktop {
  @apply hidden flex-col space-y-24
}

.PillarsLeft {
  composes: PillarsDesktop;
  @apply justify-end
}

.PillarsRight {
  composes: PillarsDesktop;
  @apply justify-start
}

.PillarDesktop_button {
  @apply [background:linear-gradient(159.89deg,_#1e1e33,_#030311)]
    box-border
    flex
    flex-row
    h-[118px]
    items-center
    justify-center
    p-2
    relative
    rounded
    text-center
    text-xl
    w-[220px]
}

.PillarContent {
  @apply flex-1 
  flex 
  flex-col 
  p-3 
  items-center
  gap-[10px]
}

.PillarDesktop_buttonHeading {
  @apply text-xl font-bold text-accent-blue
}

.PillarDesktop_buttonSubheading {
  @apply text-sm text-primary-white
}

@screen lg {
 
  .PillarsOverview_body {
    @apply flex -mr-[3rem]
  }

  .PillarsDesktop {
    @apply flex 
  }
  
  .PillarsOverview_body  figure {
    @apply flex justify-center items-center mx-4;
    scale: 140%;
    width: 305px;
  }
  
  .PillarDesktop_leftButton___indented {
    composes: PillarDesktop_button;
    left: 75px
  }

  .PillarDesktop_rightButton___indented {
    composes: PillarDesktop_button;
    right: 75px
  }
}