.Welcome {
  @apply flex
    min-h-screen
    flex-col
    self-stretch;
}

.Welcome_content {
  @apply flex
    grow
    flex-col
    items-center
    justify-center
    gap-10
    self-stretch
    px-6;
}

.Welcome_logo {
  @apply ml-0 block w-32 p-6;
}

.Welcome_headingText {
  @apply text-center text-4xl;
}

.Welcome_buttons {
  @apply flex
    w-full
    flex-col
    justify-center
    gap-5;
}

.Welcome_button {
  @apply w-full;
}

@screen lg {
  .Welcome_logo {
    @apply w-80 px-24 py-10;
  }

  .Welcome_headingText {
    @apply mb-12 text-center text-5xl;
  }

  .Welcome .Welcome_buttons {
    @apply flex-row;
  }

  .Welcome_button {
    @apply max-w-[20rem];
  }
}
