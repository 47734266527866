.Signup_cover {
  @apply fixed top-0 left-0 w-full h-auto min-h-full flex px-4 z-50 bg-primary-black/90
}

.Signup_modal {
  @apply relative w-full m-auto p-12 bg-primary-black rounded-md text-xl text-center;
  max-width: 500px;
}
.Signup_modal h2 {
  @apply text-5xl my-4 mt-3;
}

.Signup_modalIcon {
  @apply mx-auto my-0 text-7xl text-center;
}

.Signup_modalIcon img {
  @apply w-10
}

.Signup_modalIcon .icon__wrapper {
  @apply inline-block;
}

.Signup_modalClose {
  @apply absolute top-3 right-3 text-3xl
}

.Signup_modalClose svg *[stroke] {
  @apply stroke-shade-400
}
