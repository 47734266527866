.SubmitButton_container {
  @apply mb-4 mt-auto flex w-full flex-col justify-center;
}

.SubmitButton_buttonText {
  @apply hidden;
}

.SubmitButton_iconContainer {
  @apply hidden;
}

@screen lg {
  .SubmitButton_button {
    width: 300px;
    @apply flex flex-row items-center space-x-4;
  }

  .SubmitButton_buttonText {
    @apply flex items-center space-x-2 text-xs opacity-70;
  }

  .SubmitButton_buttonTextHighlight {
    @apply text-lg font-normal;
  }

  .SubmitButton_iconContainer {
    @apply inline-block opacity-70;
  }

  .SubmitButton_container {
    @apply my-4 flex flex-row items-center justify-end space-x-2;
  }
}
