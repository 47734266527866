.Signup {
  @apply mx-auto
    max-w-xl
    p-8
    pb-12
    !important;
}

.Signup_headline {
  @apply mb-6 text-4xl;
}

.Signup_subheadline {
  @apply my-6 text-shade-300;
}

.Signup_submit .Button {
  @apply w-full;
}

.Signup a {
  @apply text-accent-blue
    no-underline
    hover:text-accent-blue
    hover:underline
    !important;
}

.EAWarning {
  @apply my-2
    mb-8
    flex
    w-full
    items-center
    gap-4
    bg-accent-blue
    p-3
    text-black;
}

.EAWarning_label {
  @apply leading-6;
}

.EAWarning .icon__wrapper {
  @apply text-3xl;
}

.EAWarning_label .EAWarning_redirectLink {
  @apply font-bold
    text-primary-black
    hover:text-primary-black
    hover:underline
    !important;
}

.Signup_notice {
  @apply mt-2 text-center leading-6;
}

.Signup_noticeSubheading {
  @apply text-xs text-shade-300;
}


.Signup .PhoneNumberField_input {
  @apply text-primary-white 
}

.Signup .PhoneNumberField {
  @apply w-full
}

.Signup .PhoneNumberField_countryCode {
  @apply text-primary-white
}
