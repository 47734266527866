.CommitmentsButton {
  @apply bg-primary-black !important;
  @apply box-border
    duration-300
    flex
    flex-col
    h-full
    p-8
    relative
    text-left
    text-white
    transition-all
    w-full
    gap-4
}

.CommitmentsButton.CommitmentsButton___active {
  @apply bg-gradient-to-r
    border-4
    from-accent-blue
    opacity-100
    text-white
    to-accent-blue
}

.CommitmentsButton.CommitmentsButton___active .CommitmentsButton_checkbox {
  @apply border-primary-black
}

.CommitmentsButton_checkboxIcon {
  @apply text-2xl
}

.CommitmentsButton_wrapper {
  @apply border
    border-white
    h-full
    p-px
    relative;
}

.CommitmentsButton_wrapper.CommitmentsButton_wrapper___active {
  @apply bg-gradient-to-r
    border-transparent
    from-accent-blue
    to-accent-blue
}

.CommitmentsButton_wrapper___active .CommitmentsButton_checkbox {
  @apply bg-gradient-to-r
    border-transparent
    from-accent-blue
    text-primary-black
    to-accent-blue
}

.CommitmentsButton_wrapper___active .CommitmentsButton_headingIcon *[fill] {
  @apply stroke-accent-blue
}

.CommitmentsButton_wrapper___active .CommitmentsButton_headingIcon *[stroke] {
  @apply stroke-accent-blue
}

.CommitmentsButton_heading {
  @apply flex
    items-center
    gap-4
    text-base
    w-full
}

.CommitmentsButton_headingIcon {
  @apply text-[2.5rem]
}

.CommitmentsButton_headingTitle {
  @apply flex-grow text-xl font-bold
}

.CommitmentsButton_checkbox {
  @apply absolute 
    top-4
    right-4
}

@screen lg {
  .CommitmentsButton {
    @apply p-8 py-12
  }
  .CommitmentsButton_wrapper:hover {
    @apply bg-gradient-to-r
      border-transparent
      from-accent-blue
      to-accent-blue;
  }
}