.Tooltip {
  @apply relative py-0.5 min-w-max flex items-center font-body;
}

.Tooltip_label {
  @apply text-sm text-shade-400 cursor-help;
}

.Tooltip_icon {
  @apply cursor-help;
}

.Tooltip_label + .Tooltip_icon {
  @apply ml-4
}

.Tooltip_content {
  @apply absolute
    bg-primary-black
    text-xs
    text-shade-400
    px-4 py-3
    rounded
    z-10
    opacity-0
    transition-opacity 
    duration-500
    pointer-events-none;
}

.Tooltip:hover .Tooltip_content {
  @apply inline-block opacity-100 pointer-events-auto
}

.Tooltip:hover .Tooltip_content a:hover {
  @apply underline;
}

.Tooltip___top .Tooltip_content {
  @apply left-1/2 -ml-40 bottom-10;
}

.Tooltip___bottom .Tooltip_content {
  @apply right-0 left-0 top-8 max-w-sm;
  min-width: 288px;
}

.Tooltip___bottom .Tooltip_icon:first-child + .Tooltip_content {
  @apply left-auto
}

.Tooltip___right .Tooltip_content {
  @apply top-2;
  left: 110%;
}

.Tooltip___left .Tooltip_content {
  @apply top-0;
  right: 105%;
}

@screen lg {
  .Tooltip_content {
    @apply w-80
    bg-accent-blue
    text-white
    text-center
    px-3 py-2 
    rounded-sm;
  }

  .Tooltip___bottom .Tooltip_content {
    @apply top-12 left-1/2 right-auto;
  }

  .Tooltip___top .Tooltip_content::after {
    @apply absolute top-full left-1/2 -ml-40 border-transparent border-t-accent-blue;
    content: ' ';
    margin-left: -8px;
    border-width: 8px;
  }

  .Tooltip___bottom .Tooltip_content::after {
    @apply absolute bottom-full left-1/2 -ml-40 border-transparent border-b-accent-blue;
    content: ' ';
    margin-left: -8px;
    border-width: 8px;
  }

  .Tooltip___right .Tooltip_content::after {
    @apply absolute top-4 right-full -ml-40 border-transparent border-r-accent-blue;
    content: ' ';
    margin-top: -8px;
    border-width: 8px;
  }

  .Tooltip___left .Tooltip_content::after {
    @apply absolute top-6 -ml-40 border-transparent border-l-accent-blue;
    content: ' ';
    left: 150%;
    margin-top: -8px;
    border-width: 8px;
  }
}
