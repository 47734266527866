@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: Move this to WCL */
.Tooltip__container .react-tooltip {
  min-width: 200px;
  width: 200px;
  @apply z-10
    bg-shade-200
    leading-relaxed
    opacity-100;
}


@layer base {
  /* Overrides */
  .FormField {
    @apply my-0 mt-2;
  }

  .Dropdown {
    @apply w-full;
  }

  .Dropdown .Dropdown_control {
    padding: 0 !important;
  }

  .Dropdown .Dropdown_control select {
    @apply text-primary-white !important;
  }

  .Footer {
    @apply my-8 p-0;
  }

  .EAWarning {
    @apply rounded-md
  }

  .EAWarning .Icon_wrapper {
    @apply text-2xl
  }

  .PhoneNumberField_input {
    @apply text-primary-white 
  }

  .PhoneNumberField {
    @apply w-full
  }
  
}

@layer components {
  ._section {
    @apply flex w-full flex-col;
  }

  ._spacer {
    @apply grid;
  }

  /* TODO: Rename this to something more generic. */
  ._fieldContainer {
    @apply mb-8 flex flex-grow flex-col;
  }

  ._textHeading {
    @apply mb-4 leading-snug text-accent-blue;
  }

  ._textSubheading {
    @apply mb-10;
  }

  @screen lg {
    ._textHeading {
      @apply mb-5;
    }
    ._section {
      @apply px-24;
    }
    ._fieldContainer {
      @apply justify-center;
    }
  }
}
