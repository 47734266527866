.Waiting {
  @apply mb-10
    w-full
    flex-1
    justify-start;
}

.Waiting_heading {
  @apply mb-10 flex flex-grow flex-col gap-4;
}

.Waiting_headingText {
  @apply font-body
    text-3xl
    leading-normal
    lg:text-6xl;
}

.Waiting_content {
  @apply flex flex-col gap-2;
}

.Waiting_intro {
  @apply text-sm lg:mr-20 lg:text-base;
}

.Waiting_icon {
  @apply relative text-7xl;
}

.Waiting_button {
  @apply my-8 w-full;
}

.Waiting_button button {
  @apply w-full lg:w-auto;
}

.Waiting_emailClients {
  @apply my-4;
}

.Waiting_referral {
  @apply my-4 flex flex-col space-y-4 bg-primary-black p-4;
}

.Waiting_referralDescription {
  @apply flex flex-row items-start space-x-2;
}

.Waiting_referralDescription .icon__wrapper {
  @apply mt-1.5;
}

.Waiting_referral .Button {
  @apply border-transparent bg-accent-blue text-black;
}

@screen md {
  .Waiting_button {
    @apply inline-block w-auto;
  }
}

@screen lg {
  .Waiting {
    @apply justify-center;
  }

  .Waiting_heading {
    @apply w-full gap-8;
  }

  .Waiting_content {
    @apply w-full flex-row-reverse gap-10;
  }

  .Waiting_icon {
    font-size: 10rem;
  }

  .Waiting_icon,
  .Waiting_heading {
    @apply mt-0;
  }
}
